<template>
  <div class="columns is-gapless">
    <div class="column is-flexible">
      <div class="signup box">
        <div class="has-text-left ml-3">
          <router-link to="/">
            <img width="160" src="@/../public/logo.png" alt="VO2Fast"/>
          </router-link>
        </div>
        <h2 class="title is-2 has-text-left mt-6">Inscription à l'espace pro</h2>
        <p class="has-text-left">Vous cherchez des offres intéressantes pour votre CE <br/>
          Vous souhaitez remercier vos clients <br/>
          Vous désirez incentiver vos salariés <br/>
          Vous cherchez des offres à associer à la vente de vos produits <br/>
          Nous vous offrons la possibilité de commander des abonnements en volume à prix réduits (jusqu’à -50%). <br/>
          C’est par ici <br/>
        </p>
        <p class="has-text-left is-italic has-text-color-accent has-text-weight-semibold mt-5">{{ $t('general.required_fields') }}</p>
        <form @submit.prevent="onSignup()" class="w-100p">
          <b-input size="is-large" v-model="email" name="email" :placeholder="$t('account.email')+'*'" autocomplete="off"/><br/>
          <b-input size="is-large" v-model="username" name="username" :placeholder="$t('account.user_name')+'*'" autocomplete="off"/><br/>
          <b-input size="is-large" v-model="password"  name="password" :placeholder="$t('account.password')+'*'" type="password" autocomplete="off" password-reveal/><br/>
          <b-input size="is-large" v-model="repassword"  name="repassword" :placeholder="$t('account.confirm_password')+'*'" type="password" autocomplete="off"/><br/>

          <p v-html="$t('account.accept_terms')"/>

          <b-button size="is-large" class="is-primary has-text-weight-bold mt-3" native-type="submit">
            {{ $t('account.signup') }}
          </b-button>

          <p v-if="success" class="has-text-success">{{ $t('account.created') }}</p>
          <p v-if="error" class="has-text-danger">{{ $t(errormsg) }}</p>
          <p class="mt-3">{{ $t('account.already_have_an_account') }} <router-link class="has-text-weight-semibold" to="/login">{{ $t('account.to_login') }}</router-link></p>

          <p class="mt-7 is-size-7">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </p>
        </form>
      </div>
    </div>
    <img class="column is-half fit-cover" v-if="$root.isDesktop()" src="~@/assets/loginImage.jpg"/>
  </div>
</template>

<script>
import i18n from '@/plugins/vuei18n.js';

export default {
  name: 'BusinessSignup',
  props: {

  },
  data() {
    return {
      error: false,
      success: false,
      errormsg: '',
      email: '',
      username: '',
      password: '',
      repassword: '',
    };
  },
  methods: {
    checkMail(email){
      return (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
    },

    checkData(){
      this.errormsg = 'errors.account.';
      if(this.email == ""){
        this.errormsg += 'enter_email';
        this.error = true;
        return false;
      }
      if(!this.checkMail(this.email)){
        this.errormsg += 'invalid_email';
        this.error = true;
        return false;
      }
      if(this.username == ""){
        this.errormsg += 'enter_name';
        this.error = true;
        return false;
      }
      if(this.checkMail(this.username)){
        this.errormsg += 'username_not_email';
        this.error = true;
        return false;
      }
      if(this.password == ""){
        this.errormsg += 'enter_password';
        this.error = true;
        return false;
      }
      if(this.password != this.repassword){
        this.errormsg += 'different_password';
        this.error = true;
        return false;
      }
      this.errormsg = '';
      return true;
    },

    trackSignup(){
      try{
        this.$gtag.event('sign_up');
        window.fbq('track', 'Lead');
      }catch(err){
        console.error(err);
      }
    },

    async checkIfBot(){
      const SCORE_THRESHOLD = 0.4;
      try{
        await this.$recaptchaLoaded(); //Recaptcha on signup
        const token = await this.$recaptcha('signup');
        let result = await this.post('/recaptcha', { username: this.username, token: token, action: 'signup' });
        if(result.score <= SCORE_THRESHOLD){
          let answer = Math.round(Math.random()*20 + 10);
          let a1 = Math.round(Math.random()*answer);
          let a2 = answer - a1;
          const riddleResult = await new Promise((resolve, reject) => {
            this.$buefy.dialog.prompt({
              message: this.$t('account.prove_human') + '\n' + this.$t('account.riddle') + a1 + ' + ' + a2 + ' ?',
              trapFocus: true,
              onConfirm: (value) => resolve(value),
              onCancel: () => resolve(-1),
            });
          });

          if(riddleResult != answer){
            console.error('Bot found !');
            this.error = true;
            this.errormsg = this.$t('account.bad_answer');
            return true;
          }
        }
      }catch(err){ console.error(err); }
      return false;
    },

    async onSignup(){
      this.error = false;

      if(this.checkData()){
        localStorage.setItem('selected_plan', this.$route.query.plan);
        var data = { email: this.email, username: this.username, password: this.password, referral: this.referral,
          locale: i18n.locale, meta_infos: this.$metaInfos, signup_type: 'BUSINESS' };
        try{
          if(await this.checkIfBot())
            return;

          await this.post('/signup', data);
          this.error = false;
          this.success = true;
          this.trackSignup();

          await this.login();
        }catch(err){
          this.error = true;
          this.errormsg = err;
        }
      }
    },

    async login(){ //To login after signup
      this.error = false;
      if(this.checkData()){
        var data = { email_username: this.email, password: this.password };
        try{
          await this.$store.dispatch('login', data);
        }catch(err){
          this.errormsg = err;
          this.error = true;
        }
      }
    },
  },
  beforeMount(){  //If user is logged in, redirect ASAP to the dashboard
    if(this.$store.getters.isLoggedIn)
      this.$router.push('/dashboard');
  },
};
</script>

<style lang="scss" scoped>


.signup{
  padding: 20px;
  @include from($desktop){ padding: 100px; }
  min-height: 100vh;
}
.success {
  color: #42b983;
}

.error {
  color: red;
}
</style>
